/* eslint-disable */

const oauthconfig = {
    "domain": "ravensweb6b460df9-6b460df9-staging.auth.us-east-2.amazoncognito.com",
    "scope": [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://www.ravenseye.pxt.amazon.dev/",
      "redirectSignOut": "https://www.ravenseye.pxt.amazon.dev/",
    "responseType": "token"
      
  }
  
  const oauthconfigLocal = {
    "domain": "ravensweb6b460df9-6b460df9-staging.auth.us-east-2.amazoncognito.com",
    "scope": [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://www.ravenseye.pxt.amazon.dev/",
      "redirectSignOut": "https://www.ravenseye.pxt.amazon.dev/",
    "responseType": "token"
      
  }
  
  export {oauthconfig, oauthconfigLocal};