import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './PeopleTechSecurity.png'
import './index';
import { Amplify, Auth } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';



const App = () => {
  
  //these are safe to store in the code
  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-2:5e2a3d95-ce09-439d-a1ba-25b8a0666372',
      region: 'us-east-2',
      userPoolId: 'us-east-2_bcJ7ocuGY',
      userPoolWebClientId: '6a4mti1lkagdip4p7h3ohd5n3p',
      mandatorySignIn: false,
      oauth: {
        domain: 'ravensweb6b460df9-6b460df9-staging.auth.us-east-2.amazoncognito.com',
        scope: [
          'phone',
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: 'https://www.ravenseye.pxt.amazon.dev/',
        redirectSignOut: 'https://www.ravenseye.pxt.amazon.dev/',
        clientId: '6a4mti1lkagdip4p7h3ohd5n3p',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  });


  const [inputValue, setInputValue] = useState('');
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [reportButtonClicked, setReportButtonClicked] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  
  
  //this is for Midway authentication
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => { setSignedIn(true) })
      .catch(() => { setSignedIn(false) })
  }, []);

  //inputValue is the value of the input field  
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleReportButtonClick = () => {
    setReportButtonClicked(true);
    window.open(url);
    setMessage('');
    setLoading(false);
  }

 
  const handleSubmit = async (event) => {
    event.preventDefault();
    //checks the given url is valid or not
    // const urlRegex = /^https?:\/\/([\da-z.-]+)\.([a-z.]{2,})([\/\w.-]*)*\/?$/;
    const urlRegex = /^(https?:\/\/)(www\.)?[\w-]+(\.[\w-]+)+([/?#].*)?$/;

    if (!inputValue) {
      setMessage({ text: 'Required! Please enter a URL.', color: 'red' });
      setTimeout(() => {
        setMessage('');
      }, 2000);
      return;
    } else if (!urlRegex.test(inputValue)) {
      setMessage({ text: 'Invalid URL! Please enter a valid URL.', color: 'red' });
      setTimeout(() => {
        setMessage('');
      }, 2000);
      return;
    } else {
      
      setMessage({
        text: (
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <span>Scan is initiated for <span style={{ color: 'red', fontWeight: 'bold' }}>{inputValue}
            </span>, please wait... &nbsp;&nbsp;
            </span>
            

          </div>
        ),
        color: 'green'
      });
    };
    setInputValue('');
    //show the loading bar immediately after the scan button is clicked
    setLoading(true);
    



    try {
      axios.post('https://0xpovwt184.execute-api.us-east-2.amazonaws.com/prod/scan', { url: inputValue })
      //POST request returns a unique identifier for the scan, scan_id, and stores in response.data
        .then(response => {
          const scanId = response.data;
            //first GET request rerieves the pre-signed url, stores in response.data
            //the actual report is not ready yet, but the pre-signed url is ready
            axios.get('https://0xpovwt184.execute-api.us-east-2.amazonaws.com/prod/scan', { params: { id: scanId } })
              .then(response => {
                const preSignedUrl = response.data;
                setTimeout(() => {
                  const interval_id = setInterval(() => {
                    //second GET request gets the content of the pre-signed url, and checks if the report is ready or not
                    //poll method = periodically send requests to the endpoint to check if the report is ready or not
                    axios.get(preSignedUrl)
                      .then(contentResponse => {
                          //if the report is ready, the status code is 200
                        if(contentResponse.status === 200) {
                          //the report is ready, so the loading bar is hidden
                          setLoading(false);
                          //the pre-signed url is stored in the url state
                          setUrl(preSignedUrl);
                          //the message is updated to show the report is ready
                          setReportButtonClicked(false);
                          //clears the poll method, no more GET requests are sent to the server
                          clearInterval(interval_id);

                        } else {
                          console.log('Scan is in progress...');
                        }
                      })
                      .catch((error) => {
                        if (error.response && error.response.status !== 404) {
                          console.log('2nd GET error:', error);
                        }

                      })
                  }, 4000); //poll GET request every 4 seconds, to make sure it is ready
                }, 45000); //wait 45 seconds before the second GET request, this is for reducing the GET requests sent to the server
              })
              .catch((error) => {
                if (!axios.isCancel(error)) {
                  console.log('1st GET error:', error);
                  setLoading(false);
                }
              });
        })
        .catch(error => {
          setLoading(false);
          console.log('POST error:', error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    };

  };




  return (

    // MAIN_CONTAINER
    <div className="main-container">
      {signedIn ? (
        <>
          {/* HEADER */}
          <header className='header'>
            <div className='logo-wrapper'>
              <img src={logo} alt="logo" className='pt-sec-logo' />
            </div>

            <nav className='header-menu'>
              <a href="https://w.amazon.com/bin/view/PeopleTech/Security/ThirdPartyProgram/RavensEye" target="_blank" rel='noreferrer noopener'>Wiki</a>
              <a href="https://code.amazon.com/packages/RavensEye_Web/trees/mainline" target="_blank" rel='noreferrer noopener'>Code Repository</a>
              <a href="https://w.amazon.com/bin/view/PeopleTech/Security/ThirdPartyProgram/RavensEye/Checks" target="_blank" rel='noreferrer noopener'>Checks</a>
              <a href="https://w.amazon.com/bin/view/PeopleTech/Security/ThirdPartyProgram/RavensEye/Checks#:~:text=Active-,Adding,-New%20Checks" target="_blank" rel='noreferrer noopener'>Add Features</a>
              <a href="https://w.amazon.com/bin/view/PeopleTech/Security/ThirdPartyProgram/RavensEye/Contact/" target="_blank" rel='noreferrer noopener'>Contact Team</a>
              <a href="https://w.amazon.com/bin/view/PeopleTech/Security/ThirdPartyProgram/RavensEye/Operations/FAQ/" target="_blank" rel='noreferrer noopener'>FAQ</a>
            </nav>
          </header>

          {/* SEARCH INPUT & SUBMIT BUTTON */}
          <div className='page-wrapper'>
            <h1 className='title'>RavensEye</h1>
            
            

            <form onSubmit={handleSubmit}>
            <div className='search-text'>
              <p className='text'>example format: https://amazon.com</p>
            </div>
              <input id='scan-url' type="text" value={inputValue} onChange={handleChange} placeholder='Enter URL...' className='search-input' />
              <button type='submit' onClick={handleSubmit} className='scan-button'>Scan</button>

              {message && <p className='message-display' style={{ color: message.color }}>{message.text}</p>}
              
              {/* the loading circle */}
              <div style={{marginTop: '5px'}}>
              {loading && <CircularProgress size={20}/> }
              </div>

              {url && !reportButtonClicked && (
                <div style={{ display: 'block' }}>
                  <button className='report-button' onClick={handleReportButtonClick}>Click Here to See The Report</button>
                </div>
              )}

            </form>
          </div>
        </>
      ) : (
        //for Midway Authentication - OIDC is the federate provider
        <button onClick={() => Auth.federatedSignIn({ customProvider: "OIDC" })} style={{ display: "block", margin: "auto", marginTop: "50px", marginBottom: "50px", width: "200px", height: "50px", fontSize: "20px" }}>Midway Authentication</button>
      )}
    </div>

  )
};

export default App;
