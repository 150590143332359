import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import { Amplify, Auth } from 'aws-amplify';
import {oauthconfig, oauthconfigLocal} from './config/oauthconfig';
import awsconfig from './config/awsconfig';

Amplify.configure({
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-east-2:5e2a3d95-ce09-439d-a1ba-25b8a0666372',

    // (required)- Amazon Cognito Region
    region: 'us-east-2',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_bcJ7ocuGY',

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: '6a4mti1lkagdip4p7h3ohd5n3p',

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'ravensweb6b460df9-6b460df9-staging.auth.us-east-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: 'https://www.ravenseye.pxt.amazon.dev/',
      redirectSignOut: 'https://www.ravenseye.pxt.amazon.dev/',
      clientId: '6a4mti1lkagdip4p7h3ohd5n3p',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

Auth.configure({ oauth: oauthconfig });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
  
);



